import * as React from 'react'
import Layout from '../@lekoarts/gatsby-theme-cara/components/layout'
import { Box } from 'theme-ui'
import Footer from '../@lekoarts/gatsby-theme-cara/components/footer'
import RecruitmentHead from '../@lekoarts/gatsby-theme-cara/components/Recruitment/RecruitmentHead'
import { RecruitmentBody } from '../@lekoarts/gatsby-theme-cara/components/Recruitment/RecruitmentBody'

const Recruitment = () => (
  <Layout>
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        overflow: 'hidden scroll',
        transform: 'translate3d(0px, 0px, 0px)',
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          position: 'absolute',
          width: '100%',
          height: ['900vh', '900vh', '700vh', '450vh'],
          transform: 'translate3d(0px, 0px, 0px)',
        }}
      >
        <RecruitmentHead height="100dvh" />
        <RecruitmentBody
          top="100dvh"
          height={['845vh', '845vh', '645vh', '350vh']}
        />
        <Footer />
      </Box>
    </Box>
  </Layout>
)

export default Recruitment
